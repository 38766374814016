.tagify {
  @apply border-neutral-100 rounded-lg border-solid border;
}

.tagify.tagify--focus:not(.tagify--readonly) {
  @apply outline-none border-primary-200 ring ring-primary-100;
}

.tagify.tagify--readonly {
  border: none;
}

.tagify .tagify__input {
  @apply px-2 py-[6px];
}

.tagify .tagify__tag div {
  @apply pt-[2px] pb-[2px];
}
