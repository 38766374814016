@use 'styles/variables';

.print-area-preview {
  width: 214px;
  height: 214px;
  background-color: variables.$surface-on-secondary;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 1rem;

  .print-area {
    border: 1px solid black;
    position: absolute;
  }

  .print-area-bleed {
    border: 1px solid #c43636;
    position: absolute;
  }

  .print-area-safety-margin {
    border: 1px dashed rgba(0, 0, 0, 0.54);
    position: absolute;
  }
}
