.settings-container {
  background-color: white;
  overflow: auto;
  position: relative;
  display: flex;
  flex: 320px 0 0;
  width: 320px;
  flex-direction: column;
  border-right: 1px solid gainsboro;
}

.settings-container__title {
  flex-shrink: 0;
  height: 4rem;
  display: flex;
  align-items: center;
  color: #45474a;
  border-bottom: 1px solid gainsboro;
  padding: 0rem 1rem;

  .previous {
    padding-right: 1rem;
    transform: scaleX(0.8) scaleY(1.5);
  }

  .previous:hover {
    cursor: pointer;
  }
}
