@use 'styles/variables';

$selected-grey: #e9e9ec;

.builder {
  font-size: 0.875rem;

  .select__control {
    border-radius: variables.$primary-corner-radius;
    height: auto;
    font-size: 14px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: 1.5rem;

    &.select__control--is-focused {
      border-color: variables.$kickflip-blue;

      .select__placeholder {
        transform: translateY(-50%);
      }
    }

    input {
      color: variables.$kickflip-gray-800 !important;
      -webkit-font-smoothing: antialiased;
    }

    .select__value-container {
      padding-left: 0.625rem;
      padding-right: 1rem;

      &.select__value-container--has-value div {
        color: variables.$kickflip-gray-800;
      }
    }

    .select__placeholder {
      padding-left: 0;
      padding-right: 0;
      margin-left: 1rem;
      font-size: 14px;
      width: 100%;
    }
  }

  .select__menu .select__menu-list {
    .select__option--optimized:not(.select__option--is-selected):hover,
    .select__option--is-focused {
      background-color: rgba(128, 177, 255, 0.33) !important;
    }

    .select__option--is-selected {
      background-color: variables.$kickflip-blue !important;
    }
  }

  .expansion-panel {
    .expansion-panel__title,
    .expansion-panel__content {
      padding: 0;
    }
  }

  .slider__container {
    width: 100%;
  }

  .sketch-picker {
    input {
      box-shadow: none !important;
      border: 1px solid variables.$mdc-text-field-border-color !important;
      border-radius: variables.$primary-corner-radius !important;

      &:hover {
        border-color: rgba(0, 0, 0, 0.87) !important;
      }

      &:focus {
        outline: none;
        border-color: variables.$kickflip-blue !important;
      }
    }

    input ~ span {
      font-weight: 500 !important;
      color: variables.$mdc-text-input-color !important;
      width: 80%;
    }
  }

  .button-group {
    &.button-group--vertical {
      display: block;
    }

    .button-group__divider {
      height: auto;
    }
  }

  .text-style_svg__a {
    stroke-linejoin: bevel;
  }

  .slider__container {
    .slider__numberinput {
      border-color: #cfe1ff;

      &:hover,
      &:focus {
        border-color: variables.$kickflip-blue;
      }
    }

    .slider::-webkit-slider-thumb {
      background: variables.$kickflip-blue;
    }

    .slider::-moz-range-thumb {
      background: variables.$kickflip-blue;
    }

    .slider::-ms-thumb {
      background: variables.$kickflip-blue;
    }
  }
}

.builder__panels-container {
  flex-grow: 1;
  display: flex;
  height: 100%;
  max-height: calc(100% - #{variables.$builder-top-bar-height});

  .builder__panels-containers-right {
    width: 252px;
    position: relative;
    flex-shrink: 0;
  }

  .builder__center-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    position: relative;

    &.builder__center-section--hidden {
      opacity: 0;
      flex: 0% 0 1;
      max-width: 0px;
    }
  }
}

#visual-editor {
  position: absolute;
  width: 100%;
  height: calc(100% - 53px);
  top: 53px;
  pointer-events: none;
  z-index: 0;
  overflow: hidden;
}

.popper-menu__container.popper-menu__container--large {
  width: 304px;
}

.popper-menu__container.popper-menu__container--creatable-select,
.popper-menu__container.popper-menu__container--importable-select {
  .popper-menu {
    min-width: 180px;
    display: flex;
    flex-direction: column;

    .popper-menu-item {
      padding: 0.5rem 1rem;

      &.popper-menu-item--main {
        color: variables.$kickflip-blue;
      }

      .popper-menu-item__label {
        overflow: hidden;
        text-overflow: ellipsis;

        &--small-text {
          font-size: 12px;
        }
      }

      .popper-menu-item__right-content {
        margin-left: auto;
      }
    }

    .popper-menu-divider-container {
      margin: 0 0.75rem;

      display: flex;
      justify-content: center;
      align-items: center;
      color: variables.$gray-700;
      font-size: 14px;
    }

    .popper-menu-divider {
      height: 0;
      flex-grow: 1;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.12);
      margin: 0 1rem;
    }

    .popper-menu-empty-state {
      display: flex;
      align-items: center;
      width: 100%;
      height: 64px;
      justify-content: center;
      color: variables.$kickflip-gray-300;
    }
  }
}

.popper-menu-scroll-container {
  max-height: 400px;
  overflow-y: auto;
}

.popper-menu-header-section {
  flex: 40px 0 0;
}

.popper-menu-footer-section {
  flex: 53px 0 0;

  border-top: 1px solid rgba(0, 0, 0, 0.06);

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0 0.5rem;

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }

  &--left-align {
    justify-content: flex-start;
  }
}
