.popper-menu__container.edit-popper-container {
  .panel {
    max-height: inherit;
    height: inherit;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .panel__content {
      flex-grow: 1;
    }
  }

  .popper-menu {
    overflow: hidden;
  }

  .resize-drag-handle {
    position: absolute;
    left: calc(126px - 35px);
    width: 70px;
    height: 4px;
    background: #c7c7c7;
    border-radius: 23px;
    bottom: 5px;
    &:hover {
      cursor: ns-resize;
    }
  }
}

.snap-indicator {
  position: fixed;
  top: 62px;
  height: calc(100vh - 70px);
  width: 252px;
  background: rgba(0, 0, 255, 0.05);
  border: 2px solid rgba(0, 0, 255, 0.1);
  z-index: 8;
  border-radius: 12px;

  &--over-panel {
    right: 8px;
  }

  &--next-to-panel {
    right: 260px;
  }
}

.drag-scrim {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 8;
}
