.circular-loading-icon {
  width: 2rem;
  height: 2rem;

  g {
    animation: rotate 1.4s linear infinite;
    transform-origin: center;
  }

  circle {
    animation:
      circle-spin 1.4s ease-in-out infinite,
      colors 5.6s ease-in-out infinite;
    stroke: #2196f3;
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    stroke-linecap: square;
    stroke-width: 4;
    transform-origin: center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes circle-spin {
    0% {
      stroke-dashoffset: 100;
    }
    50% {
      stroke-dashoffset: 20;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 100;
      transform: rotate(450deg);
    }
  }
}
