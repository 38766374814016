.popper-menu__container.edit-popper-container {
  padding: 0;

  .popper-menu {
    box-shadow: 0px 2px 6px rgb(0 0 0 / 18%);
    padding: 0;
    background-color: white;
    border-radius: 12px;
    width: 340px;
  }
}

.popper-menu-header-section {
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  display: flex;
  align-items: center;

  padding: 0 0.5rem;

  &--draggable:hover {
    cursor: grab;
  }

  .popper-menu-header-section__drag-handle {
    width: 20px;
    fill: #7d7d7d;

    &--hide {
      opacity: 0;
    }
  }

  .popper-menu-header-section__title {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0.2rem;
  }

  .popper-menu-header-section__close-button {
    width: 20px;
    height: 20px;
    padding: 4px;
  }
}
