@use 'styles/variables';

.top-bar__tabs {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1024px;
  height: 100%;

  .top-bar__tab {
    cursor: pointer;
    font-weight: 500;
    color: variables.$gray-500;
    margin: 0 0.75rem;
    border-bottom: transparent 2px solid;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include variables.regular-transition(color);

    &:hover:not(.top-bar__tab--selected):not(.top-bar__tab--disabled) {
      border-color: variables.$gray-500;
    }

    &--selected {
      border-color: variables.$kickflip-blue;
      color: variables.$kickflip-blue;
    }

    &--disabled {
      color: rgba(0, 0, 0, 0.38);
      cursor: unset;
    }
  }
}
