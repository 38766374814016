.default-outline-section {
  padding: 1rem;

  .panel__row {
    display: flex;
    align-items: center;

    .panel__field-label {
      margin-right: 0.5rem;
    }

    input[type='range'] {
      margin-left: auto;
      width: 100px;
    }
  }

  .add-button {
    margin-top: 1rem;
  }
}
