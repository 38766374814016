.estimate-slider.estimate-slider {
  height: 44px;
}

.estimate-slider > input {
  height: 44px;
}

.estimate-slider > input::-webkit-slider-thumb {
  @apply bg-primary-500/80 rounded-full border-2 border-solid border-neutral-100 h-4 w-4 -translate-y-5 transition-transform shadow-xs appearance-none;
}

.estimate-slider > input::-moz-range-thumb {
  @apply bg-primary-500/80 rounded-full border-2 border-solid border-neutral-100 h-4 w-4 -translate-y-5 transition-transform shadow-xs appearance-none;
}

.estimate-slider > input::-ms-thumb {
  @apply bg-primary-500/80 rounded-full border-2 border-solid border-neutral-100 h-4 w-4 -translate-y-5 transition-transform shadow-xs appearance-none;
}
