.builder .panel__section.lighting-section {
  .panel__group {
    margin-bottom: 0.5rem;

    .panel__row {
      margin-bottom: 0;
    }
  }

  .expansion-panel__title {
    margin-bottom: 1.5rem;
  }
}
