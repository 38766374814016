.share-button__portal {
  cursor: pointer;
  width: 100%;
  margin-top: 0.25rem;
  padding: 0.625rem 0;
  background-color: black;
  height: 55px;
}

.share-button__portal.share-button__portal--disabled {
  display: none;
}

.share-button__portal .socials-share-button {
  height: 2rem;
  display: table;
  margin: auto;
}

.share-button__portal .socials-share-button .icon.share {
  height: 100%;
  justify-content: center;
}

.share-button__portal .socials-share-button .icon.share svg {
  fill: white;
}

.share-button__portal .socials-share-button .social-share {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.25px;
  cursor: pointer;
  border: none;
  color: white;
  display: table-cell;
  vertical-align: middle;
}

.share-button__portal .share-button__circular-loading-icon {
  height: 2rem;
  text-align: center;
}

.share-button__portal .share-button__circular-loading-icon .circular-loading-icon {
  width: 2rem;
  height: 2rem;
  background-color: black;
}
