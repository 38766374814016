@use 'styles/variables';

.print-area-dimensions {
  margin-top: 1.5rem;

  .panel__row {
    .margin-left__auto {
      margin-left: auto;
    }

    .select__control {
      height: 24px;
      min-height: 24px;
      width: 4rem;
      margin-left: auto;
      flex-grow: 0.5;
      border-radius: 4px 4px 0 0;
      border-width: 0 0 1px 0;
      border-color: rgba(0, 0, 0, 0.54);
      margin-bottom: 0;

      .select__indicator {
        display: none;
        padding: 0;
        height: 20px;
        width: 20px;
      }

      &:hover,
      &:focus {
        .select__indicator {
          display: initial;
          color: rgba(0, 0, 0, 0.54);
        }
      }

      .select__control--is-focused {
        box-shadow: none;
        border-width: 0 0 1px 0;
        caret-color: transparent;
      }

      .select__input,
      .select__single-value,
      .select__value-container,
      .select__value-container--has-value div,
      input {
        font-size: 14px;

        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        color: variables.$kickflip-gray-800 !important;
        letter-spacing: 0.15px;
        padding-left: 0;
        padding-bottom: 0;
        height: 24px;
      }
    }

    .select__menu {
      width: 7.625rem;
    }
  }

  .input-number-without-arrow {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      text-align: left;
      padding-left: 1rem;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      text-align: left;
      padding-left: 1rem;
    }
  }
}
