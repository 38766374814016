//MARGINS
$interactable-margin: 8px;

//BORDERS
$primary-corner-radius: 8px;

//COLORS
$smooth-black: #3c3c3c;
$mczr-gray: #3d3d3d;
$mdc-text-input-color: #747474;
$gray-text-color: rgba(0, 0, 0, 0.54);
$lighter-gray-text-color: rgba(0, 0, 0, 0.38);
$choice-panel-main-text-color: gray;
$rules-grey: #9c9c9c;
$lighter-gray: #b5b5b5;
$mdc-text-field-border-color: rgba(0, 0, 0, 0.16);
$warning-message-background-color: #fffae6;
$hoverable-grey: #f4f4f5;
$very-light-purple: #e8e5f9;

$critical-red: #bb0000;
$error-color: #e24040;
$error-color-highlight: #e94948;

$image-background-contrast-background-color: #ededed;
$secondary-background-color: #fafafa;
$slighty-purple-white: #f9f8fe;
$surface-on-secondary: #efefef;

$mczr-purple: #683bf4;
$kickflip-blue: #0021f5;
$mdc-theme-primary: #683bf4;
$mczr-purple-light: #7d58f5;
$mczr-purple-lighter: #ad95fa;
$button-group-secondary: #c5b5fb;

$card-background: #f3f2f7;

$mczr-teal: #00c3d3;

$warning-color: #e39a24;

$gray-100: #f8f8f8ab;
$gray-200: #dfdfdf36;
$gray-300: #dfdfdf78;
$gray-500: #818181a8;
$gray-700: #0000008a;
$gray-900: #000000de;

//ICONS
$icon-very-small: 14px;

//MISC
$builder-top-bar-height: 54px;

$third-background-color: #f4f5f9;

@mixin regular-transition($property) {
  transition: $property ease-in-out 0.2s;
}

// NEW THEME
$kickflip-blue: #0021f5;
$kickflip-blue-light: #bfd8ff;

$kickflip-orange: #ffb421;
$kickflip-orange-light: #ffd89d;

$kickflip-gray-50: #f7f7f7;
$kickflip-gray-100: #e1e1e1;
$kickflip-gray-300: #9e9e9e;
$kickflip-gray-600: #757575;
$kickflip-gray-700: #616161;
$kickflip-gray-800: #424242;
$kickflip-gray-900: #212121;
