.popper-menu__container.edit-popper-container {
  .popper-menu {
    width: 252px;

    .answer-panel.answer-panel--create.builder {
      height: calc(100vh - 70px);
      max-height: 540px;
    }

    .answer-panel {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .panel__content {
        flex-grow: 1;
      }
    }
  }
}

.answer-panel {
  .sketch-picker {
    & > div:first-child {
      padding-bottom: 160px !important;
    }
  }
}
