@use 'styles/variables';

$footer-height: 65px;

.new-question-menu {
  overflow-y: auto;
  margin-bottom: $footer-height;
  width: 558px;
  height: 100%;

  .new-question-menu__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 24px;
    height: 24px;
    color: variables.$gray-900;
    z-index: 3;
    cursor: pointer;

    svg {
      height: 12px;
    }
  }

  .new-question-menu__step {
    opacity: 1;
    transition:
      opacity 250ms ease,
      height 500ms ease;
    overflow: hidden;

    .new-question-menu__step-container {
      padding: 1.5rem;
    }

    &.new-question-menu__step--disabled {
      opacity: 0.38;

      .new-question-menu__card {
        cursor: inherit;
        pointer-events: none;

        &:hover {
          border-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    &.new-question-menu__step--hidden {
      opacity: 0;
      .new-question-menu__card {
        cursor: inherit;
        pointer-events: none;
      }
    }

    .new-question-menu__title {
      font-weight: 500;
      margin-bottom: 1rem;
      display: block;
      position: relative;
    }

    .new-question-menu__grid {
      display: grid;
      position: relative;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0.5rem;
    }

    .new-question-menu__card {
      position: relative;
      display: flex;
      padding-left: 0.5rem;
      align-items: center;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      background-color: white;
      width: 164.5px;
      height: 44px;

      .new-question-menu__card-message {
        position: absolute;
        right: 0.25rem;
        top: 0.25rem;
        border-radius: 4px;
        background-color: variables.$surface-on-secondary;
        color: variables.$gray-text-color;
        padding: 0.25rem;
        width: 24px;
        height: 24px;

        svg {
          fill: currentColor;
          width: 100%;
          height: 100%;
        }
      }

      &:hover,
      &.new-question-menu__card--selected {
        border-color: variables.$kickflip-blue;
      }

      &.new-question-menu__card--selected {
        border-width: 2px;

        padding-top: calc(0.5rem - 1px);
        padding-bottom: calc(0.5rem - 1px);
        padding-left: calc(0.5rem - 1px);
      }

      &:hover {
        &.new-question-menu__card--disabled {
          border-color: rgba(0, 0, 0, 0.1);
        }
      }

      &.new-question-menu__card--disabled {
        opacity: 0.5;
      }

      .type-icon {
        width: 26px;
        height: 26px;
        margin-right: 0.5rem;
        padding: 6px;
        border-radius: 4px;
      }

      .new-question-menu__label {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .new-question-menu__footer {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    border-top: 1px solid #f4f5f9;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: white;
    height: $footer-height;

    .new-question-menu__footer-message {
      font-size: 12px;
      color: variables.$gray-900;
      position: relative;
      flex: 1;
      height: 100%;
      margin-right: 1rem;
    }

    .new-question-menu__footer-action:not(:disabled) {
      background-color: variables.$kickflip-blue;
      color: white;
      margin-left: auto;
    }
  }
}

.popper-menu {
  .new-question-menu {
    max-height: calc(100vh - $footer-height - 2rem);
  }

  .new-question-menu__footer {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
