.moveable-custom.moveable-control-box .moveable-control,
.moveable-custom.moveable-control-box .moveable-rotation .moveable-control,
.moveable-custom.moveable-control-box .moveable-control-base {
  @apply bg-white;
  @apply border-none;
  @apply transition-colors;
  box-shadow:
    0 0 4px 1px rgba(57, 76, 96, 0.15),
    0 0 0 1px rgba(43, 59, 74, 0.3);
  pointer-events: all;

  &:not([aria-disabled='true']) {
    @apply hover:bg-primary-500 hover:text-white;
  }

  &[aria-disabled='true'] {
    opacity: 0.5 !important;
  }
}

.moveable-custom.moveable-control-box .moveable-control:not([aria-disabled='true']),
.moveable-custom.moveable-control-box .moveable-rotation .moveable-control:not([aria-disabled='true']),
.moveable-custom.moveable-control-box .moveable-control-base:not([aria-disabled='true']) {
  @apply hover:bg-primary-500 hover:text-white;
}

.moveable-custom.moveable-control-box .moveable-control[aria-disabled='true'],
.moveable-custom.moveable-control-box .moveable-rotation .moveable-control[aria-disabled='true'],
.moveable-custom.moveable-control-box .moveable-control-base[aria-disabled='true'] {
  opacity: 0.5 !important;
}

.moveable-custom.moveable-control-box .moveable-control {
  @apply w-3 h-3 -ml-1.5 -mt-1.5;
}

.moveable-custom.moveable-control-box .moveable-control.moveable-w,
.moveable-custom.moveable-control-box .moveable-control.moveable-e {
  @apply w-1.5 h-5 rounded -ml-[3px] -mt-2.5;
}

.moveable-custom.moveable-control-box .moveable-control.moveable-n,
.moveable-custom.moveable-control-box .moveable-control.moveable-s {
  @apply w-5 h-1.5 rounded -ml-2.5 -mt-[3px];
}

.moveable-custom.moveable-control-box .moveable-control.moveable-control-active {
  @apply bg-primary-500;
}

.moveable-custom.moveable-control-box .moveable-line {
  @apply bg-primary-500;
  box-shadow: 0px 0px 0px 1px #1200ff33;
}

.moveable-custom.moveable-control-box.bottom .moveable-line[data-line-key='render-line-0'],
.moveable-custom.moveable-control-box.bottom .moveable-line[data-line-key='render-line-1'],
.moveable-custom.moveable-control-box.bottom .moveable-line[data-line-key='render-line-3'] {
  opacity: 0.4;
  height: 2px !important;
  background-color: transparent !important;
  background-image: linear-gradient(to right, blue 66%, rgba(255, 255, 255, 0) 0%) !important;
  box-shadow: none !important;
  background-position: left center;
  background-size: 8px 2px;
  background-repeat: repeat-x;
}

.moveable-custom.moveable-control-box.dashed .moveable-line[data-line-key='render-line-0'],
.moveable-custom.moveable-control-box.dashed .moveable-line[data-line-key='render-line-1'],
.moveable-custom.moveable-control-box.dashed .moveable-line[data-line-key='render-line-2'],
.moveable-custom.moveable-control-box.dashed .moveable-line[data-line-key='render-line-3'] {
  opacity: 0.4;
  height: 2px !important;
  background-color: transparent !important;
  background-image: linear-gradient(to right, blue 66%, rgba(255, 255, 255, 0) 0%) !important;
  box-shadow: none !important;
  background-position: left center;
  background-size: 8px 2px;
  background-repeat: repeat-x;
}

.moveable-control-box {
  z-index: 10 !important;
}

.moveable-control-drag-target {
  @apply cursor-move;
}

.moveable-draggable-view-dragging {
  @apply cursor-move;
}
